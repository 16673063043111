@use "definitions" as vb;

code, pre, tt {
    font-family: Consolas, "Liberation Mono", Courier, monospace;
    font-size: 12px;
}

code, tt {
    margin: 0 2px;
    padding: 0.3em;
    white-space: nowrap;
    background-color: vb.$box-body-background-color;
    border: 1px solid #ccc;
    border-radius: 1em;
    -moz-border-radius: 1em;
    -webkit-border-radius: 1em;
}

pre > tt, pre > code {
    margin: 0;
    padding: 0;
    white-space: pre;
    border: none;
    background: transparent;
}

pre, code {
    background-color: vb.$box-background-color;
    color: vb.$box-color;
    border: vb.$box-border;
    font-size: 13px;
    line-height: 19px;
    overflow: auto;
    padding: 6px 10px;
    border-radius: 1em;
    -moz-border-radius: 1em;
    -webkit-border-radius: 1em;
}

pre pre, pre code, pre tt {
    background-color: transparent;
    border: none;
}

pre pre {
    margin: 0;
    padding: 0;
}

