@use "definitions" as vb;

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  body.bogado.net {
    color: vb.$page-text-color;
    background-color: vb.$page-background-color;
    font-family: vb.$main-font;
    line-height: 1.4;
    font-size: 14px;
    line-height: 1.7;

    a {
      color: vb.$link-text-color;
      text-decoration: none;

      &:hover, &:visited {
        text-decoration: underline;
        outline: 0;
      }

      &:focus {
        outline: thin dotted;
      }

      &.anchor:focus {
        outline: none;
      }
    }

    img {
      border: vb.$box-border;
      border-radius: 1em;
      -moz-border-radius: 1em;
      -webkit-border-radius: 1em;
    }

    .logo {
      img {
        border: 0;
      }
    }

    .hdlist {
      img {
        border: 0;
      }

      table {
        tr {
          border: 0;
          background: none;
        }
      }
    }


    p, blockquote, ul, ol, dl, table, pre, hr {
      margin: 15px 0;
    }

    blockquote {
      border-left: vb.$box-border;
      padding: 0 15px;

      & > :first-child {
        margin-top: 0;
      }

      & > :last-child {
        margin-bottom: 0;
      }
    }

    hr {
      border: 0 none;
      color: vb.$box-border-color;
      height: 4px;
      padding: 0;
    }

    .page {
      margin: 5px;
      overflow: visible;
      width: 100%;
    }
  }
}
