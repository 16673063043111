@use "bogado_net";

@font-face {
    font-family: "Vine";
    src: url(/fonts/Vine-Regular.otf);
}

* {
    margin: 0;
    padding: 0;
}

