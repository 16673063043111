@use "definitions" as vb;

h1 {
    font-size: 3em;
    color: vb.$headers-text-color;
    margin: 30px 0 5px;
    padding: 0;
    font-family: 'vine';
    font-weight: normal;
    cursor: text;
    position: relative;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    & + p, & + tt, & + code {
        margin-top: 0;
        @inherit %closer;
    }
}

h2 {
    @extend h1;
    font-size: 2em;
    margin-top: 20px;
    clear: both;
}

h3 {
    @extend h2;
    font-size: 1.5em;
}

h4 {
    @extend h3;
    font-size: 1.25em;
}

h5 {
    @extend h4;
    font-size: 1em;
}

h6 {
    @extend h5;
    font-size: 1em;
    font-weight: regular;
    text-decoration: italic;
}

.header {
    margin: vb.$page-margin;
    padding: 0;
    overflow: hidden;
    border-bottom: vb.$box-border;

    * {
        height: 1.3em;
    }

    a {
        color: vb.$head-text-color;
    }

    h1 {
        font-size: 4em;
        float: left;
        font-family: vine;
        font-weight: normal !important;

        line-height: normal;
        margin: 0;
        vertical-align: top;
    }
}
