@use "definitions" as vb;

%list {
    padding-left: 30px;

    li {
        margin: 0px;

        p:first-child {
            display: inline-block;
        }
    }

    ul {
        list-style-type: circle;
    }

    &.none {
        list-style-type: none;
    }
}

ul {
    @extend %list;
    list-style-type: disc;
}

ol {
    @extend %list;
    list-style-type: decimal;
}

dl {
    padding: 0;

    dt {
        font-size: 14px;
        font-weight: bold;
        font-style: italic;
        padding: 0;
        margin: 15px 0 5px;

        &:first-child {
            padding: 0;
        }

        & > :first-child {
            margin-top: 0;
        }

        & > :last-child {
            margin-bottom: 0;
        }

        dd {
            margin: 0 0 15px;
            padding: 0 15px;

            & > :first-child {
                margin-top: 0;
            }

            & > :last-child {
                margin-bottom: 0;
            }
        }
    }
}

table {
    padding: 0;
    border-collapse: collapse;
    border-spacing: 0;

    tr {
        border-top: vb.$box-border;
        background-color: vb.$table-odd-background-color;
        margin: 0;
        padding: 0;

        &:nth-child(2n) {
            background-color: vb.$table-even-background-color;
        }

        %table_cell {
            text-align: none;
            margin: 0;
            padding: 6px 13px;

            & > :first-child {
                margin-top: 0;
            }

            & > :last-child {
                margin-bottom: 0;
            }
        }

        & th {
            @extend %table_cell;
            font-weight: bold;
        }

        & td {
            @extend %table_cell;
        }
    }
}
