$page-margin: 1em;
$page-padding: 10px;
$page-background-color: #ebebe0;

$box-background-color: white;
$box-body-background-color: white;
$box-color: black;
$box-border-color: #ccc !default;
$box-border: 1px solid $box-border-color !default;

$table-odd-background-color: #bbf !default;
$table-even-background-color: #88b !default;

$button-background-color: linear-gradient(45deg, #f4f4f4, #ececec);
$button-hover-background-color: linear-gradient(45deg, #e0e0d1, #c2c2a3);

$max-size: 900px;
$sidebar-size: 200px;

$page-text-color: #222;
$head-text-color: #e91909;
$headers-text-color: #04828c;
$link-text-color: $head-text-color;

$main-font: 'Roboto Slab', serif;
$header-font: 'Vine';
$menu-font: 'Vine';

