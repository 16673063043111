@use "definitions" as vb;

%rounded_border {
    border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
}

%left-side {
    @media(min-width: 940px) {
        right: vb.$sidebar-size;
        max-width: vb.$max-size - vb.$sidebar-size - vb.$page-padding;
        padding: vb.$page-padding;
    }

    @media(max-width: 940px) {
        right: 0;
    }
}

%right-side {
    @media(min-width: 940px) {
        display: block;
        position: fixed;
        width: vb.$sidebar-size;
        left: vb.$max-size - vb.$sidebar-size;
    }

    @media(max-width: 940px) {
        & * {
            display: none;
        }
    }
}

@mixin hamburguer-menu {
    &::before {
        content: "\2630";
    }

    & * {
        display: none;
    }

    &:hover * {
        display: block;
    }
}

.links {
    li {
        list-style-type: none;

        &#hover {
            border: solid 1px gray;
        }
    }
}

.sidebar {
    @extend %right-side;
    top: 80px;

    @media(max-width: 940px) {
        @include hamburguer-menu
    }

    &:hover * {
        display: block
    }

    & ~ div {
        @extend %left-side;
    }

    .body {
        margin-left: 20px;
    }

    p {
        margin: 13px 0 0;
    }

    & > p:first-child {
        margin-top: 10px;
    }

    .dir {
        border-top: 1px solid #aaa;
        padding-top: 4px;
        line-height: 1.5;
        font-size: 1.5em;
    }

    .title {
        color: vb.$headers-text-color;
        line-height: 1.6;
        padding-top: 10px;
        font-size: 2.2em;
    }
}

.page {
    display: block;
    position: absolute;
    max-width: 900px;
}

.body {
    margin: vb.$page-margin;

    /* content wrapper */
    & > .content{
        @extend %left-side;
        left: 0;
        overflow: visible;
    }
}

/* @section footer */

.footer {
    @extend %right-side;
    bottom: 0;
    padding: vb.$page-margin;
}

.header {
    .header-content {
        margin-bottom: 1.5em;
    }
}

.toc {
    font-family: vine;
    padding: 0px 5px;
    margin: 0;
    font-weight: normal !important;
    background-color: none;
    font-size: 2em;
    float: left;
    margin-bottom: 20px;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .current_page {
        display: none;
    }

    & > ul {
        font-size: 14px;
    }

    & > div {
        border: none;
    }
}

